import React, { useEffect } from 'react'
import { AppTextEditor2 } from '../../../components/AppTextEditor2'

export const DashboardIndex = () => {
  useEffect( () => {

  }, [])

  return (
    <>
      <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
          <h1 className='text-center h4'>DASHBOARD</h1>
      </div>
      <div className="container" style={{ height: '100vh'}}>
        <div className='row justify-content-center'>
          <div className='col-md-12 mb-5'>
            <h1 className='h3 text-center'>WELCOME TO KEJARI MURUNG RAYA APP </h1>
          </div>
          {/* <div className='col-md-8'>
            <div className='card card-body'>
              <h4 className='text-center'>PENGUMUMAN</h4>
              <hr></hr>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}
