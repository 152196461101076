// export const primaryColor = "#94D13A"
export const primaryColor = "#23A455"
export const primaryColor2 = "#70BA02"
export const secondaryColor = "#11104d"
export const secondaryColor2 = "#060534"
export const goldColor = '#FDD247'

// export const appApi = "http://localhost:8001/"
export const appApi = "https://murung-jaya-api.aldyweb.com/"

export const AppName = "MURUNG RAYA";