import React from 'react'
import { useNavigate } from 'react-router-dom';
import HTMLparse from 'html-react-parser'

export const BeritaCard = (props) => {
    const nav = useNavigate();

    const elip = (string,longChar) => {
        string  = (string.length > longChar ? string.substring(0,longChar)+"..." : string  )
        return string
    }

  return (
    <>
        <div className="card">
            {/* <img src="..." className="card-img-top" alt="..." /> */}
            <div className="card-body">
                <div className='d-flex justify-content-center'>
                    <img src={props.thumbnail} alt='TYAGA DEV'  style={{
                         objectFit: 'cover', objectPosition: 'center'
                    }}/>
                </div>
                <h5 className="card-title ellipsis mt-3">{props.title}</h5>
                <p className="card-text">{HTMLparse( elip(props.body,30))}</p>
                <button type='button' className="btn app-btn-primary" onClick={ () => {
                window.location.href = '/publikasi/berita/'+props.slug;
                // nav('/publikasi/berita/'+props.slug)
                }}>Selengkapnya</button>
            </div>
        </div>
    </>
  )
}
