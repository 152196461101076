import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AppHeroHeader } from '../../components/AppHeroHeader'
import { AppLoader1 } from '../../components/AppLoader1';
import { AppWidget } from '../../components/AppWidget';
import { BeritaCard } from '../../components/BeritaCard';
import { appApi } from '../../Constant';

export const Kegiatan = () => {
    const [loader, setLoader ] = useState(true);

    const [dataBerita, setDataBerita] = useState([]);
    const [type, setType] = useState('');

    const handleFilter = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getBeritaByType',
                headers: {
    
                },
                data: {
                    type: 'Kegiatan'
                }
            });
    
                if ( res.data.code === '200') {
                    // alert(res.data.message);
                    setDataBerita(res.data.data.dataBerita);
                    setLoader(false);
                } else {
                    alert(res.data.message);
                }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    useEffect( () => {
        handleFilter()
    }, [])
    
    if ( loader ) {
        return (
            <AppLoader1 />
        )
    }
  return (
    <>
        <AppHeroHeader text='KEGIATAN' />
        
        <div className='container py-3'>
            <div className='row'>
                <div className='col-md-9'>
                    <div className='row '>
                        {
                            dataBerita.length > 0 ?
                                dataBerita.map( (v,i) => { return (
                                    <div className='col-md-4 mb-3'>
                                        <BeritaCard thumbnail={v.thumbnail} title={v.title} body={v.body} slug={v.slug} />
                                    </div>
                                )
                                })
                            :
                                <div className='col-md-12 mb-3'>
                                    <span className='text-center'>BELUM ADA KEGIATAN</span>
                                </div>
                        }
                    </div>
                </div>

                <div className='col-md-3'>
                        <AppWidget />
                </div>
            </div>
            
        </div>
    </>
  )
}
