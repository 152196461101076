import React, { useEffect, useState } from 'react'
import { FaBullhorn, FaFacebook, FaIdCard, FaIdCardAlt, FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AppLoader1 } from '../../components/AppLoader1'
import { getDataStrukturOrganisasi } from '../../service/api/StrukturOrganisasiApi'

export const StrukturOrganisasiIndex = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [DataStruktur, setDataStruktur] = useState([])

    const getDataStruktur = async () => {
        setIsLoading(true);
        const res = await getDataStrukturOrganisasi()
        setIsLoading(false);
        if ( res.data.code === '200') {
            setDataStruktur(res.data.data.data);
            // alert(res.data.message);
        } else {
            alert(res.data.message);
        }
    }

    useEffect( () => {
        getDataStruktur()
    }, [])

    if ( isLoading ) {
        return (
            <AppLoader1 />
        )
    }
    
  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>STRUKTUR ORGANISASI</h1>
        </div>
        <div className='container' >
            <div className='row'>
                {
                    DataStruktur.map((v,i) => {
                        return (
                            <div className='col-md-3 mt-3'>
                                <div className='card card-body'>
                                    <div className='row justy-content-center align-items-center align-content-center'>
                                        <img src={v.foto} alt='TYAGA DEV'  style={{
                                         height: 200, objectFit: 'cover', objectPosition: 'center'
                                        }}/>
                                    </div>
                                    <div>
                                        <h4>{v.nama}</h4>
                                    </div>
                                    <div>
                                        <h6 className='text-muted'>{v.title}</h6>
                                    </div>
                                    <div>
                                        <small>Visit me!</small>
                                        <div>
                                            <a className='text-dark' href={ v.instagram} target="_blank"> 
                                                <FaInstagram size={30} color={'#8a3ab9'} />
                                            </a>
                                            <a className='text-dark' href={ v.facebook} target="_blank"> 
                                                <FaFacebook size={30} color={'#4267B2'} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) 
                }
            </div>
        </div>
    </>
  )
}
