import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDataTable } from '../../../components/AppDataTable';
import { appApi } from '../../../Constant';

export const DataWBSIndex = () => {
    
    const nav = useNavigate();

    const [dataWBS, setDataWBS] = useState([]);

    const columnsDataWBS = [
        {
            name: 'Nama Pelapor',
            selector: row => row.nama_pelapor,
        },
        {
            name: 'Email',
            selector: row => row.email_pelapor,
        },
        {
            name: 'No. HP',
            selector: row => row.no_hp_pelapor,
        },
        {
            name: 'Tanggal Lapor',
            selector: row => row.sys_created_on,
        },
        {
            name: 'Action',
            selector: row => <button type='button' className='btn btn-primary' onClick={ () => nav('/admin/reformasi-birokrasi/data-wbs-detail', {
                state: {
                    item: row
                }
            })}> Detail </button>,
        },
    ];

    const getAllWBS = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/reformasi-birokrasi/WBS/getAllWBS',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setDataWBS(res.data.data.dataWBS);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllWBS();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA WBS</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>

                    <AppDataTable title="Data WBS" colData={columnsDataWBS} rowData={dataWBS} />
                </div>
            </div>
        </div>
    </>
  )
}
