import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AppHeroHeader } from '../../components/AppHeroHeader'
import { AppLoader1 } from '../../components/AppLoader1';
import { AppWidget } from '../../components/AppWidget';
import { BeritaCard } from '../../components/BeritaCard';
import { appApi } from '../../Constant';
import { getDataSetupCategory } from '../../service/api/GlobalApi';

export const JadwalSidang = () => {
    const [loader, setLoader ] = useState(true);

    const [data_category, set_data_category] = useState([]);
    const [dataBerita, setDataBerita] = useState([]);
    const [type, setType] = useState('');

    const handleFilter = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getBeritaByType',
                headers: {
    
                },
                data: {
                    type: type
                }
            });
    
                if ( res.data.code === '200') {
                    // alert(res.data.message);
                    setDataBerita(res.data.data.dataBerita);
                    setLoader(false);
                } else {
                    alert(res.data.message);
                }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    useEffect( () => {
        handleFilter();
        handleCategory();
    }, [])

    const handleCategory = async () => {
        try {
            const res = await getDataSetupCategory();
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                set_data_category(res.data.data.data);
                console.log('array data',res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }
    
    if ( loader ) {
        return (
            <AppLoader1 />
        )
    }
  return (
    <>
        <AppHeroHeader text='JADWAL SIDANG' />
        
        <div className='container py-3'>
            <div className='row'>
                <div className='col-md-9'>
                    {/* <iframe src="http://sipp.pn-muarateweh.go.id/list_jadwal_sidang" width={'100%'} height={'100vh'} allowFullScreen /> */}
                    <iframe src="http://sipp.pn-muarateweh.go.id/list_jadwal_sidang" width={'100%'} style={{
                        height: '100vh',
                        width: 'cover',
                    }} allowFullScreen />
                </div>

                <div className='col-md-3'>
                        <AppWidget />
                </div>
            </div>
            
        </div>
    </>
  )
}
