import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser'
import { AppDataTable } from '../../../components/AppDataTable';
import { appApi } from '../../../Constant';
import { getAllDataWidget,  } from '../../../service/api/WidgetApi';

export const DataWidget = () => {
    
    const nav = useNavigate();

    const [Data, setData] = useState([]);

    const columnsData = [
        {
            name: 'Nama',
            selector: row => row.nama,
        },
        {
            name: 'Action',
            selector: row => <button className='btn app-btn-primary' onClick={ () => handleEdit(row) }>EDIT</button>,
        },
    ];

    const handleEdit = (data) => {
        console.log(data);
        nav('/admin/widget/edit', {
            state : data
        }) 
    }


    const getAllData = async () => {
        try {
            const res = await getAllDataWidget();
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setData(res.data.data.data);
                console.log(res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllData();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA WIDGET</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mb-3'>
                        <button className='btn app-btn-primary' onClick={ () => nav('/admin/widget/add')}>TAMBAH</button>
                    </div>

                    <AppDataTable title="DATA WIDGET" colData={columnsData} rowData={Data} />
                </div>
            </div>
        </div>
    </>
  )
}
