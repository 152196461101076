import React from 'react'
import { AppHeroHeader } from '../../components/AppHeroHeader'

export const NilaiSejarahIndex = () => {
  return (
    <>
        <AppHeroHeader text="Nilai & Sejara" />
        <div className="container">
            <p style={{textAlign: 'center'}}>
                <span style={{textDecoration: 'underline'}}>
                <span style={{color: '#008000'}}>
                    <strong>TRI KRAMA ADHYAKSA</strong>
                </span>
                </span>
            </p>
            <p style={{textAlign: 'center'}}>
                <strong>
                <span style={{color: '#0000ff'}}>“Satya Adhi Wicaksana”</span>
                </strong>
            </p>
            <p style={{textAlign: 'left'}}>
                <strong>SATYA :</strong>
                <br />Kesetiaan yang bersumber pada rasa jujur, baik terhadap Tuhan Yang Maha Esa, terhadap diri pribadi dan keluarga maupun kepada sesama manusia.
            </p>
            <p>
                <strong>ADHI :</strong>
                <br />Kesempurnaan dalam bertugas dan berunsur utama pada pemilikan rasa tanggung jawab terhadap Tuhan Yang Maha Esa, terhadap keluarga dan terhadap sesama manusia.
            </p>
            <p>
                <strong>WICAKSANA :</strong>
                <br />Bijaksana dalam tutur kata dan tingkah laku, khususnya dalam pengetrapan kekuasaan dan kewenangannya.
            </p>
            <p style={{textAlign: 'center'}}>
                <span style={{textDecoration: 'underline'}}>
                <span style={{color: '#008000'}}>
                    <strong>SEJARAH KEJAKSAAN R. I</strong>
                </span>
                </span>
            </p>
            <p>
                <strong>Sebelum Reformasi</strong>
            </p>
            <p>Istilah Kejaksaan sebenarnya sudah ada sejak lama di Indonesia. Pada zaman kerajaan Hindu-Jawa di Jawa Timur, yaitu pada masa Kerajaan Majapahit, dikenal istilah dhyaksa, adhyaksa, dan dharmadhyaksa yang sudah mengacu pada posisi dan jabatan tertentu di kerajaan. Istilah-istilah ini berasal dari bahasa kuno, yakni dari kata-kata yang sama dalam Bahasa Sansekerta.</p>
            <p>Seorang peneliti Belanda, W.F. Stutterheim mengatakan bahwa dhyaksa adalah pejabat negara di zaman Kerajaan Majapahit, tepatnya di saat Prabu Hayam Wuruk tengah berkuasa (1350-1389 M). Dhyaksa adalah hakim yang diberi tugas untuk menangani masalah peradilan dalam sidang pengadilan. Para dhyaksa ini dipimpin oleh seorang adhyaksa, yakni hakim tertinggi yang memimpin dan mengawasi para dhyaksa tadi.</p>
            <p>Kesimpulan ini didukung peneliti lainnya yakni H.H. Juynboll, yang mengatakan bahwa adhyaksa adalah pengawas <em>(opzichter</em>) atau hakim tertinggi ( <em>oppenrrechter</em>). Krom dan Van Vollenhoven, juga seorang peneliti Belanda, bahkan menyebut bahwa patih terkenal dari Majapahit yakni Gajah Mada, juga adalah seorang adhyaksa. <br />Pada masa pendudukan Belanda, badan yang ada relevansinya dengan jaksa dan Kejaksaan antara lain adalah <em>Openbaar Ministerie</em>. Lembaga ini yang menitahkan pegawai-pegawainya berperan sebagai <em>Magistraat</em> dan <em>Officier van Justitie</em> di dalam sidang <em>Landraad</em> (Pengadilan Negeri), <em>Jurisdictie Geschillen</em> (Pengadilan Justisi) dan <em>Hooggerechtshof</em> (Mahkamah Agung ) dibawah perintah langsung dari Residen / Asisten Residen. </p>
            <p>Hanya saja, pada prakteknya, fungsi tersebut lebih cenderung sebagai perpanjangan tangan Belanda belaka. Dengan kata lain, jaksa dan Kejaksaan pada masa penjajahan belanda mengemban misi terselubung yakni antara lain:</p>
            <ol>
                <li>Mempertahankan segala peraturan Negara ;</li>
                <li>Melakukan penuntutan segala tindak pidana</li>
                <li>Melaksanakan putusan pengadilan pidana yang berwenang</li>
            </ol>
            <p>Fungsi sebagai alat penguasa itu akan sangat kentara, khususnya dalam menerapkan delik-delik yang berkaitan dengan <em>hatzaai artikelen</em> yang terdapat dalam <em>Wetboek van Strafrecht</em> (WvS). </p>
            <p>Peranan Kejaksaan sebagai satu-satunya lembaga penuntut secara resmi difungsikan pertama kali oleh Undang-undang pemerintah zaman pendudukan tentara Jepang No. 1/1942, yang kemudian diganti oleh Osamu Seirei No.3/1942, No.2/1944 dan No.49/1944. Eksistensi kejaksaan itu berada pada semua jenjang pengadilan, yakni sejak <em>Saikoo Hoooin</em> (pengadilan agung), <em>Koootooo Hooin</em> (pengadilan tinggi) dan <em>Tihooo Hooin</em> (pengadilan negeri). Pada masa itu, secara resmi digariskan bahwa Kejaksaan memiliki kekuasaan untuk: </p>
            <ul>
                <li>Mencari (menyidik) kejahatan dan pelanggaran;</li>
                <li>Menuntut Perkara;</li>
                <li>Menjalankan putusan pengadilan dalam perkara kriminal;</li>
                <li>Mengurus pekerjaan lain yang wajib dilakukan menurut hukum.&nbsp;</li>
            </ul>
            <p>Begitu Indonesia merdeka, fungsi seperti itu tetap dipertahankan dalam Negara Republik Indonesia. Hal itu ditegaskan dalam Pasal II Aturan Peralihan UUD 1945, yang diperjelas oleh Peraturan Pemerintah (PP) Nomor 2 Tahun 1945. Isinya mengamanatkan bahwa sebelum Negara R.I. membentuk badan-badan dan peraturan negaranya sendiri sesuai dengan ketentuan Undang-Undang Dasar, maka segala badan dan peraturan yang ada masih langsung berlaku.</p>
            <p>Karena itulah, secara yuridis formal, Kejaksaan R.I. telah ada sejak kemerdekaan Indonesia diproklamasikan, yakni tanggal 17 Agustus 1945. Dua hari setelahnya, yakni tanggal 19 Agustus 1945, dalam rapat Panitia Persiapan Kemerdekaan Indonesia (PPKI) diputuskan kedudukan Kejaksaan dalam struktur Negara Republik Indonesia, yakni dalam lingkungan Departemen Kehakiman.</p>
            <p>Kejaksaan RI terus mengalami berbagai perkembangan dan dinamika secara terus menerus sesuai dengan kurun waktu dan perubahan sistem pemerintahan. Sejak awal eksistensinya, hingga kini Kejaksaan Republik Indonesia telah mengalami 22 periode kepemimpinan Jaksa Agung. Seiring dengan perjalanan sejarah ketatanegaraan Indonesia, kedudukan pimpinan, organisasi, serta tata cara kerja Kejaksaan RI, juga juga mengalami berbagai perubahan yang disesuaikan dengan situasi dan kondisi masyarakat, serta bentuk negara dan sistem pemerintahan.</p>
            <p>Menyangkut Undang-Undang tentang Kejaksaan, perubahan mendasar pertama berawal tanggal 30 Juni 1961, saat pemerintah mengesahkan Undang-Undang Nomor 15 tahun 1961 tentang Ketentuan-Ketentuan Pokok Kejaksaan RI. Undang-Undang ini menegaskan Kejaksaan sebagai alat negara penegak hukum yang bertugas sebagai penuntut umum (Pasal 1), penyelenggaraan tugas departemen Kejaksaan dilakukan Menteri / Jaksa Agung (Pasal 5) dan susunan organisasi yang diatur oleh Keputusan Presiden. Terkait kedudukan, tugas dan wewenang Kejaksaan dalam rangka sebagai alat revolusi dan penempatan kejaksaan dalam struktur organisasi departemen, disahkan Undang-Undang Nomor 16 tahun 1961 tentang Pembentukan Kejaksaan Tinggi.</p>
            <p>Pada masa Orde Baru ada perkembangan baru yang menyangkut Kejaksaan RI sesuai dengan perubahan dari Undang-Undang Nomor 15 Tahun 1961 kepada Undang-Undang Nomor 5 Tahun 1991, tentang Kejaksaan Republik Indonesia. Perkembangan itu juga mencakup perubahan mendasar pada susunan organisasi serta tata cara institusi Kejaksaan yang didasarkan pada adanya Keputusan Presiden No. 55 tahun 1991 tertanggal 20 November 1991.</p>
            <p>
                <strong>Masa Reformasi</strong>
            </p>
            <p>Masa Reformasi hadir ditengah gencarnya berbagai sorotan terhadap pemerintah Indonesia serta lembaga penegak hukum yang ada, khususnya dalam penanganan Tindak Pidana Korupsi. Karena itulah, memasuki masa reformasi Undang-undang tentang Kejaksaan juga mengalami perubahan, yakni dengan diundangkannya Undang-Undang Nomor 16 Tahun 2004 untuk menggantikan Undang-Undang Nomor 5 Tahun 1991. Kehadiran undang-undang ini disambut gembira banyak pihak lantaran dianggap sebagai peneguhan eksistensi Kejaksaan yang merdeka dan bebas dari pengaruh kekuasaan pemerintah, maupun pihak lainnya.</p>
            <p>Dalam Undang-Undang No.16 Tahun 2004 tentang Kejaksaan RI, Pasal 2 ayat (1) ditegaskan bahwa “Kejaksaan R.I. adalah lembaga pemerintah yang melaksanakan kekuasaan negara dalam bidang penuntutan serta kewenangan lain berdasarkan undang-undang”. Kejaksaan sebagai pengendali proses perkara ( <em>Dominus Litis</em>), mempunyai kedudukan sentral dalam penegakan hukum, karena hanya institusi Kejaksaan yang dapat menentukan apakah suatu kasus dapat diajukan ke Pengadilan atau tidak berdasarkan alat bukti yang sah menurut Hukum Acara Pidana. Di samping sebagai penyandang <em>Dominus Litis</em>, Kejaksaan juga merupakan satu-satunya instansi pelaksana putusan pidana ( <em>executive ambtenaar</em>). Karena itulah, Undang-Undang Kejaksaan yang baru ini dipandang lebih kuat dalam menetapkan kedudukan dan peran Kejaksaan RI sebagai lembaga negara pemerintah yang melaksanakan kekuasaan negara di bidang penuntutan. </p>
            <p>Mengacu pada UU tersebut, maka pelaksanaan kekuasaan negara yang diemban oleh Kejaksaan, harus dilaksanakan secara merdeka. Penegasan ini tertuang dalam Pasal 2 ayat (2) Undang-Undang Nomor 16 Tahun 2004 tentang Kejaksaan R.I, bahwa Kejaksaan adalah lembaga pemerintah yang melaksanakan kekuasaan negara di bidang penuntutan secara merdeka. Artinya, bahwa dalam melaksanakan fungsi, tugas dan wewenangnya terlepas dari pengaruh kekuasaan pemerintah dan pengaruh kekuasaan lainnya. Ketentuan ini bertujuan melindungi profesi jaksa dalam melaksanakan tugas profesionalnya.</p>
            <p>Undang-Undang Nomor 16 Tahun 2004 tentang Kejaksaan R.I. juga telah mengatur tugas dan wewenang Kejaksaan sebagaimana ditentukan dalam Pasal 30, yaitu :</p>
            <p>(1) Di bidang pidana, Kejaksaan mempunyai tugas dan wewenang:</p>
            <ul>
                <li>Melakukan penuntutan;</li>
                <li>melaksanakan penetapan hakim dan putusan pengadilan yang telah memperoleh kekuatan hukum tetap;</li>
                <li>Melakukan pengawasan terhadap pelaksanaan putusan pidana bersyarat, putusan pidana pengawasan, dan keputusan bersyarat;</li>
                <li>Melaksanakan penyidikan terhadap tindak pidana tertentu berdasarkan undang-undang;</li>
                <li>Melengkapi berkas perkara tertentu dan untuk itu dapat melakukan pemeriksaan tambahan sebelum dilimpahkan ke pengadilan yang dalam pelaksanaannya dikoordinasikan dengan penyidik.</li>
            </ul>
            <p>(2) Di bidang perdata dan tata usaha negara, Kejaksaan dengan kuasa khusus dapat bertindak di dalam maupun di luar pengadilan untuk dan atas nama negara atau pemerintah</p>
            <p>(3) Dalam bidang ketertiban dan ketentraman umum, Kejaksaan turut menyelenggarakan kegiatan:</p>
            <ul>
                <li>Peningkatan kesadaran hukum masyarakat;</li>
                <li>Pengamanan kebijakan penegakan hukum;</li>
                <li>Pengamanan peredaran barang cetakan; <br />Pengawasan aliran kepercayaan yang dapat membahayakan masyarakat dan negara; </li>
                <li>Pencegahan penyalahgunaan dan/atau penodaan agama;</li>
                <li>Penelitian dan pengembangan hukum statistik kriminal.</li>
            </ul>
            <p>Selain itu, Pasal 31 Undang-Undang Nomor 16 Tahun 2004 tentang Kejaksaan R. I, menegaskan bahwa Kejaksaan dapat meminta kepada hakim untuk menetapkan seorang terdakwa di rumah sakit atau tempat perawatan jiwa, atau tempat lain yang layak karena bersangkutan tidak mampu berdiri sendiri atau disebabkan oleh hal-hal yang dapat membahyakan orang lain, lingkungan atau dirinya sendiri. Pasal 32 Undang-Undang Nomor 16 Tahun 2004 tersebut menetapkan bahwa di samping tugas dan wewenang tersebut dalam undang-undang ini, Kejaksaan dapat diserahi tugas dan wewenang lain berdasarkan undang-undang. Selanjutnya Pasal 33 mengatur bahwa dalam melaksanakan tugas dan wewenangnya, Kejaksaan membina hubungan kerjasama dengan badan penegak hukum dan keadilan serta badan negara atau instansi lainnya. Kemudian Pasal 34 menetapkan bahwa Kejaksaan dapat memberikan pertimbangan dalam bidang hukum kepada instalasi pemerintah lainnya.</p>
            <p>Pada masa reformasi pula Kejaksaan mendapat bantuan dengan hadirnya berbagai lembaga baru untuk berbagi peran dan tanggungjawab. Kehadiran lembaga-lembaga baru dengan tanggungjawab yang spesifik ini mestinya dipandang positif sebagai mitra Kejaksaan dalam memerangi korupsi. Sebelumnya, upaya penegakan hukum yang dilakukan terhadap tindak pidana korupsi, sering mengalami kendala. Hal itu tidak saja dialami oleh Kejaksaan, namun juga oleh Kepolisian RI serta badan-badan lainnya. Kendala tersebut antara lain:</p>
            <ul>
                <li>
                <em>Modus operandi</em> yang tergolong canggih <br />Pelaku mendapat perlindungan dari korps, atasan, atau teman-temannya <br />Objeknya rumit ( <em>compilicated</em>), misalnya karena berkaitan dengan berbagai peraturan;
                </li>
                <li>Sulitnya menghimpun berbagai bukti permulaan;</li>
                <li>Manajemen sumber daya manusia;</li>
                <li>Perbedaan persepsi dan interprestasi (di kalangan lembaga penegak hukum yang ada);</li>
                <li>Sarana dan prasarana yang belum memadai;</li>
                <li>Teror psikis dan fisik, ancaman, pemberitaan negatif, bahkan penculikan serta pembakaran rumah penegak hokum.</li>
            </ul>
            <p>Upaya pemberantasan korupsi sudah dilakukan sejak dulu dengan pembentukan berbagai lembaga. Kendati begitu, pemerintah tetap mendapat sorotan dari waktu ke waktu sejak rezim Orde Lama. Undang-Undang Tindak Pidana Korupsi yang lama yaitu UU No. 31 Tahun 1971, dianggap kurang bergigi sehingga diganti dengan UU No. 31 Tahun 1999. Dalam UU ini diatur pembuktian terbalik bagi pelaku korupsi dan juga pemberlakuan sanksi yang lebih berat, bahkan hukuman mati bagi koruptor. Belakangan UU ini juga dipandang lemah dan menyebabkan lolosnya para koruptor karena tidak adanya Aturan Peralihan dalam UU tersebut. Polemik tentang kewenangan jaksa dan polisi dalam melakukan penyidikan kasus korupsi juga tidak bisa diselesaikan oleh UU ini.</p>
            <p>Akhirnya, Undang-Undang Nomor 30 Tahun 2002 tentang Komisi Pemberantasan Tindak Pidana Korupsi, dalam penjelasannya secara tegas menyatakan bahwa penegakan hukum dan pemberantasan korupsi yang dilakukan secara konvensional selama ini terbukti mengalami berbagai hambatan. Untuk itu, diperlukan metode penegakan hukum luar biasa melalui pembentukan sebuah badan negara yang mempunyai kewenangan luas, independen, serta bebas dari kekuasaan manapun dalam melakukan pemberantasan korupsi, mengingat korupsi sudah dikategorikan sebagai <em>extraordinary crime</em>. </p>
            <p>Karena itu, Undang-Undang Nomor 30 Tahun 2002 tentang Komisi Pemberantasan Tindak Pidana Korupsi, mengamanatkan pembentukan pengadilan Tindak Pidana Korupsi yang bertugas dan berwenang memeriksa dan memutus tindak pidana korupsi. Sementara untuk penuntutannya, diajukan oleh Komisi Pemberantasan Tindak Pidana Korupsi (KPK) yang terdiri dari Ketua dan 4 Wakil Ketua yang masing-masing membawahi empat bidang, yakni Pencegahan, Penindakan, Informasi dan Data, Pengawasan internal dan Pengaduan masyarakat.</p>
            <p>Dari ke empat bidang itu, bidang penindakan bertugas melakukan penyidikan dan penuntutan. Tenaga penyidiknya diambil dari Kepolisian dan Kejaksaan RI. Sementara khusus untuk penuntutan, tenaga yang diambil adalah pejabat fungsional Kejaksaan. Hadirnya KPK menandai perubahan fundamental dalam hukum acara pidana, antara lain di bidang penyidikan.</p>
            </div>

    </>
  )
}
