import React from 'react'

export const AppHeroHeader = (props) => {
  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>{props.text}</h1>
        </div>
    </>
  )
}
