import axios from 'axios'
import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { AppLoader1 } from '../../components/AppLoader1'
import AppMap from '../../components/AppMap';
import { AppWidget } from '../../components/AppWidget';
import { appApi } from '../../Constant'

export const WBSIndex = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [nama_pelapor, set_nama_pelapor] = useState('');
    const [no_hp_pelapor, set_no_hp_pelapor] = useState('');
    const [email_pelapor, set_email_pelapor] = useState('');
    const [kritik_saran, set_kritik_saran] = useState('');
    const [pelaporan, set_pelaporan] = useState('');
    const [value, onChange] = useState(new Date());

    const sendWBS = async () => {
        setIsLoading(true);
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/sendWBS',
                headers: {
                },
                data: {
                    nama_pelapor: nama_pelapor,
                    email_pelapor: email_pelapor,
                    no_hp_pelapor: no_hp_pelapor,
                    kritik_saran: kritik_saran,
                    pelaporan: pelaporan,
                }
            });
            
            setIsLoading(false);

            if ( res.data.code === '200') {
                alert(res.data.message);
                set_nama_pelapor('');
                set_email_pelapor('');
                set_no_hp_pelapor('');
                set_kritik_saran('');
                set_pelaporan('');
            } else {
                alert(res.data.message);
            }
        } catch (error) {
            // alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

      useEffect( () => {
        // sendWBS();
      }, [])

      if ( isLoading ) {
          return (
            <AppLoader1 />
          )
      }
  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>Whistle Blowing System (WBS)</h1>
        </div>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <h5>Silahkan mengisi form WBS dibawah ini</h5>
                    <div className='mt-3'>
                        <label>Nama Anda <span className='text-danger'>*</span></label>
                        <input 
                            required
                            className='form-control'
                            placeholder='Masukan lengkap anda'
                            value={nama_pelapor}
                            onChange={ (e) => set_nama_pelapor( e.currentTarget.value.toUpperCase() )}
                        />
                    </div>

                    <div className='mt-3'>
                        <label>No HP Anda <span className='text-danger'>*</span></label>
                        <input 
                            required
                            maxLength={20}
                            className='form-control'
                            placeholder='Masukan no hp anda'
                            value={no_hp_pelapor}
                            onChange={ (e) => set_no_hp_pelapor( e.currentTarget.value )}
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Email Anda <span className='text-danger'>*</span></label>
                        <input 
                            required
                            type="email"
                            className='form-control'
                            placeholder='Masukan alamat email anda'
                            value={email_pelapor}
                            onChange={ (e) => set_email_pelapor( e.currentTarget.value )}
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Kritik / Saran </label>
                        <textarea  rows={4} cols={40} 
                            placeholder='Masukan kritik / saran anda untuk kami'
                            className='form-control'
                            value={kritik_saran}
                            onChange={ (e) => set_kritik_saran( e.currentTarget.value )}
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Hal pelaporan <span className='text-danger'>*</span></label>
                        <textarea  rows={4} cols={40} 
                            required
                            className='form-control'
                            placeholder='Masukan laporan anda'
                            value={pelaporan}
                            onChange={ (e) => set_pelaporan( e.currentTarget.value )}
                        />
                    </div>

                    <div className='mt-3'>
                        <button className="btn app-btn-primary" onClick={sendWBS}>KIRIM</button>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='mt-5'>
                        <h3>Calendar</h3>
                        <Calendar onChange={onChange} value={value} />
                    </div>
                    
                    <AppWidget />
                </div>
            </div>
        </div>
    </>
  )
}
