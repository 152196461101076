import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppTextEditor } from '../../../../components/AppTextEditor';
import { appApi } from '../../../../Constant';

import Select from 'react-select'

export const BeritaAdd = () => {
    const nav = useNavigate();

    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [bodyy, setBodyy] = useState('');
    const [file, setFile] = useState(null);
    const [category, set_category] = useState([]);
    const [DataSetupCategory, setDataSetupCategory] = useState([]);

    const getDataSetupCategory = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getDataSetupCategory',
                headers: {
    
                },
                data: {
                    type: type
                }
            });
    
                if ( res.data.code === '200') {
                    // alert(res.data.message);
                    setDataSetupCategory(res.data.data.data);
                } else {
                    alert(res.data.message);
                }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    const handleIsiBerita = (data) => {
        console.log("handle isi berita",data.data);
        setBodyy(data.data);
    }

    const handleSave = async () => {
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('title', title);
            bodyFormData.append('type', type);
            bodyFormData.append('bodyy', bodyy);
            bodyFormData.append('thumbnail', file);
            bodyFormData.append('category', JSON.stringify(category));

            console.log(category);
            const res = await axios({
                method: 'post',
                url: appApi+'api/publikasi/berita/saveBerita',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                    "Content-Type": "multipart/form-data",
                },
                data: bodyFormData
            });
    
              if ( res.data.code === '200') {
                alert(res.data.message);
                nav(-1);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getDataSetupCategory()
    }, [])

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>TAMBAH BERITA BARU</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <label className='mb-1'>Judul</label>
                        <input className="form-control" type="text" placeholder="Masukan Judul" 
                            value={title}
                            onChange={ (e) => setTitle(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Tipe</label>
                        <Select 
                            isMulti
                            options={DataSetupCategory} 
                            getOptionLabel={(option) => `${option['category_name']}`}
                            getOptionValue={(option) => `${option['id']}`}
                            onChange={ (option) => set_category(option)}
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Isi Berita</label>
                        <AppTextEditor handleIsiBerita={handleIsiBerita} />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Foto</label>
                        <input className="form-control" type="file"  placeholder="Masukan Judul" 
                            onChange={ (e) => setFile(e.target.files[0]) }
                        />
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-light mb-3 me-3' onClick={ () => nav(-1) }>CANCEL</button>
                        <button className='btn app-btn-primary mb-3' onClick={ handleSave }>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
