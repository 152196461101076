import axios from 'axios'
import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../Constant'

export const TugasWewenangIndex = () => {
    const [contentx, setContentx ] = useState('');

    const getTugasWewenang = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getTugasWewenang',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
            console.log("get", res.data.data.data1[0]);
              if ( res.data.code === '200') {
                setContentx(res.data.data.data1[0].body)
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            // alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
      }

      useEffect( () => {
        getTugasWewenang();

      }, [])

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>Tugas & Wewenang</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    {  HTMLReactParser(contentx) }
                    
                </div>
            </div>
        </div>
    </>
  )
}
