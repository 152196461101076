import React from 'react'
import DataTable from 'react-data-table-component';

export const AppDataTable = (props) => {
    const [pending, setPending] = React.useState(true);
	const [rows, setRows] = React.useState([]);
	const [columns, setColumns] = React.useState([]);

    React.useEffect(() => {
		const timeout = setTimeout(() => {
			setRows(props.rowData);
			setColumns(props.colData);
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, []);
    
  return (
    <>
        <DataTable title={props.title} columns={props.colData} data={props.rowData} progressPending={pending} pagination />
    </>
  )
}
