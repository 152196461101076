import React from 'react';

const PDFViewer = ({ url }) => {

    if ( url ) {
        return (
          <iframe src={`${url}`} 
            width="100%" height="500" 
            style={{border: 'none'}}
            allowFullScreen>
          </iframe>
        );

    }
}

export default PDFViewer;
