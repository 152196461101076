import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDataTable } from '../../../components/AppDataTable';
import { appApi } from '../../../Constant';

export const DataStrukturOrganisasi = () => {
    
    const nav = useNavigate();

    const [Data, setData] = useState([]);

    const columnsData = [
        {
            name: 'Nama',
            selector: row => row.nama,
        },
        {
            name: 'Posisi',
            selector: row => row.title,
        },
        {
            name: 'Foto',
            selector: row => <img src={row.foto} alt='TYAGA DEV'  style={{
                height: 50, objectFit: 'cover', objectPosition: 'center',
                borderRadius: 10
               }}/>,
        },
        {
            name: 'Action',
            selector: row => <button className='btn app-btn-primary' onClick={ () => handleEdit(row) }>EDIT</button>,
        },
    ];

    const handleEdit = (data) => {
        console.log(data);
        nav('/admin/adhyaksa-darmakarini/data-struktur-organisasi/edit', {
            state : data
        }) 
    }


    const getAllData = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/adhyaksa-darmakarini/struktur-organisasi/getAllData',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setData(res.data.data.data);
                console.log(res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllData();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA STRUKTUR ORGANISASI</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <button className='btn app-btn-primary' onClick={ () => nav('/admin/adhyaksa-darmakarini/data-struktur-organisasi/add')}>TAMBAH</button>
                    </div>

                    <AppDataTable title="DATA STRUKTUR ORGANISASI" colData={columnsData} rowData={Data} />
                </div>
            </div>
        </div>
    </>
  )
}
