import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logoBrand from '../assets/images/Logo-Kejaksaan-2.png'
import { AppModalMenu } from './AppModalMenu';
export const AppNavbar = () => {
    const [userToken, setUserToken] = useState(null);

    useEffect( () => {
        setUserToken(window.localStorage.getItem('userToken'));
    })

  return (
    <>
        <nav className="navbar navbar-expand-lg bg-white shadow mb-5 sticky-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logoBrand} width={200} height={50}/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    {/* <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#">Features</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#">Pricing</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link disabled">Disabled</a>
                        </li>
                    </ul> */}
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Profil
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/profil/visi-misi">Visi & Misi</Link></li>
                                <li><Link className="dropdown-item" to="/profil/tugas-wewenang">Tugas & Wewenang</Link></li>
                                <li><Link className="dropdown-item" to="/profil/nilai-sejarah">Nilai Dasar & Sejarah</Link></li>
                                <li><Link className="dropdown-item" to="/profil/profil-pimpinan">Profil Pimpinan</Link></li>
                                <li><Link className="dropdown-item" to="/struktur-organisasi">Struktur Organisasi</Link></li>
                                <li><Link className="dropdown-item" to="/profil/fasilitas-kantor">Peta & Fasilitas Kantor</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                E-PTSP
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/eptsp/pembinaan">Pembinaan</Link></li>
                                <li class="dropdown-submenu">
                                    {/* <a href="#" class="dropdown-toggle dropdown-item" data-toggle="dropdown">Aagain</a> */}
                                    <Link class="dropdown-toggle dropdown-item" to="/intelejen">Intelejen</Link>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfttGkkPM8G_S2h6TQ-UNIxpMa67BpUXr0HlTSCOiabo8wFyQ/viewform" className="dropdown-item" target='_blank'>Laporan Pengawasan Media Massa dan Cetakan</a>
                                        </li>
                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfTYVpjYjRfo8wBmSu6HfmIv-mRKHnY-W2jNOcHbTd6b53-GA/viewform" className="dropdown-item" target='_blank'>Laporan Pengawasan Aliran Kepercayaan </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    {/* <a href="#" class="dropdown-toggle dropdown-item" data-toggle="dropdown">Aagain</a> */}
                                    <Link class="dropdown-toggle dropdown-item" to="/pidum">Pidum</Link>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeWRw2Ci6zBeYEblpDCu4E6VNaDxstL2HZF5mnWp0JOxPRAlQ/viewform" className="dropdown-item" target='_blank'>Tilang Online</a>
                                        </li>
                                    </ul>
                                </li>
                                <li><Link className="dropdown-item" to="/pidsus">Pidsus</Link></li>
                                <li><Link className="dropdown-item" to="/datun">Datun</Link></li>
                                <li><Link className="dropdown-item" to="/">Barang Bukti & Rampasan</Link></li>
                                
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Pelayanan
                            </a>
                            <ul className="dropdown-menu">
                                <li> 
                                    <a className="dropdown-item"  href="https://bit.ly/lapdumasmura" target="_blank">Laporan Pengaduan Masyarakat</a>
                                </li>
                                <li> 
                                    <a className="dropdown-item"  href="https://halojpn.id/home/kn-murung-raya" target="_blank">HaloJPN</a>
                                </li>
                                <li> 
                                    <a className="dropdown-item"  href="https://tilang.kejaksaan.go.id/" target="_blank">Tilang</a>
                                </li>

                                <li> 
                                    <a className="dropdown-item"  href="http://sipp.pn-muarateweh.go.id/list_jadwal_sidang" target="_blank">Jadwal Sidang</a>
                                </li>

                                <li> 
                                    <a className="dropdown-item"  href="https://jampidum.kejaksaan.go.id/p/satker/T09uWFo1NjAwREEzNDBSM0c2NFdVdz09" target="_blank">Informasi Perkara</a>
                                </li>
                                <li> 
                                    <a className="dropdown-item"  href="https://docs.google.com/forms/d/e/1FAIpQLSebNj72BwoN_U_OLQMYRWvtRIdEOK7p9039Y-qZDS6pViszNg/viewform?usp=pp_url" target="_blank">Pengambalian Barang Bukti</a>
                                </li>
                                <li> 
                                    <Link className="dropdown-item" to={'peta-pemilu'}>Peta Pemilu</Link>
                                </li>
                                {/* <li><Link className="dropdown-item" to="/jadwal-sidang">Jadwal sidang</Link></li> */}
                            </ul>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/publikasi">Publikasi</Link>
                        </li>

                        
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Adhyaksa Darmakarini
                            </a>
                            <ul className="dropdown-menu">
                                {/* <li><a className="dropdown-item" href="#">Sejarah</a></li> */}
                                {/* <li><a className="dropdown-item" href="/struktur-organisasi">Struktur Organisasi</a></li> */}
                                <li><a className="dropdown-item" href="/kegiatan">Kegiatan</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Reformasi Birokrasi
                            </a>
                            <ul className="dropdown-menu">
                                {/* <li><a className="dropdown-item" href="#">Reinvinting Government Program</a></li> */}
                                {/* <li><a className="dropdown-item" href="#">Adhyaksa Berkarya</a></li> */}
                                <li><Link  className="dropdown-item" to="/reformasi-birokrasi/wbs">Whistle Blowing System</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Zona Integritas
                            </a>
                            <ul className="dropdown-menu">
                                <li class="dropdown-submenu">
                                    <Link class="dropdown-toggle dropdown-item" to="/intelejen">Manajemen Perubahan</Link>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Tim Kerja</a>
                                        </li>
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Dokumen Rencana Pembangunan Zona Integritas</a>
                                        </li>
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Pemantauan & Evaluasi Pembangunan Zone Integritas</a>
                                        </li>
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Perubahan Pola Pikir & Budaya Kerja</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <Link class="dropdown-toggle dropdown-item" to="/intelejen">Penataan Tata Laksana</Link>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>SOP Kegiatan Utama</a>
                                        </li>
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>E-Office</a>
                                        </li>
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Keterbukaan Informasi Publik</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <Link class="dropdown-toggle dropdown-item" to="/intelejen">Penguatan Akuntabilitas</Link>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Keterlibatan Pimpinan</a>
                                        </li>
                                        <li>
                                            <a href="#" className="dropdown-item" target='_blank'>Pengelolaan Akuntabilitas Kerja</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    {
                        userToken !== null ?
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    { window.localStorage.getItem('user_name')}
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
                                    <li>
                                        <a className="dropdown-item" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                            Menu
                                        </a>
                                    </li>

                                    <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                                    
                                </ul>
                            </li>
                        </ul>
                        :
                        <></>
                    }
                    
                </div>
            </div>
        </nav>
        <AppModalMenu />
    </>
  )
}
