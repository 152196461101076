import axios from 'axios'
import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../Constant'
import PDFViewer from '../../components/PDFViewer'
import { AppLoader1 } from '../../components/AppLoader1'

export const ProfilPimpinanIndex = () => {
    const [loading_screen, set_loading_screen] = useState(false);
    const [contentx, setContentx ] = useState('');

    const getProfilPimpinan = async () => {
        try {
            set_loading_screen(true);
            const res = await axios({
                method: 'post',
                url: appApi+'api/getProfilPimpinan',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
            set_loading_screen(false);
    
            console.log("get", res.data.data.data1[0]);
              if ( res.data.code === '200') {
                setContentx(res.data.data.data1[0].url_file)
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            // alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

      useEffect( () => {
        getProfilPimpinan();
      }, [])

      if ( loading_screen ) {
        return (
            <AppLoader1 />
        )
      }
  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>Profil Pimpinan</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <PDFViewer url={contentx} />                    
                </div>
            </div>
        </div>
    </>
  )
}
