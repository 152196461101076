import axios from "axios";
import { appApi } from "../../Constant";

export const getDataSetupCategory = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/getDataSetupCategory',
            headers: {
                // "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
            }
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}