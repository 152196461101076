import axios from "axios";
import { appApi } from "../../Constant";

export const getDataStrukturOrganisasi = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/getDataStrukturOrganisasi',
            headers: {
                // "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
            }
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}

export const getPosition = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/adhyaksa-darmakarini/struktur-organisasi/getPosition',
            headers: {
                "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
            }
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}


export const saveDataStrukturOrganisasi = async (bodyFormData) => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/adhyaksa-darmakarini/struktur-organisasi/save',
            headers: {
                "Authorization": "Bearer "+localStorage.getItem("userToken"),
                "Content-Type": "multipart/form-data",
            },
            data: bodyFormData
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}