import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { appApi } from '../../../Constant';

export const UserAdd = () => {
    const nav = useNavigate();

    const [name, setname] = useState('');
    const [password, setpassword] = useState('');
    const [password_confirmation, setpassword_confirmation] = useState('');
    const [email, setemail] = useState('');

  
    const handleSave = async () => {

        if ( !email ) {
            alert('Please fill the password');
            return false;
        }

        if ( !password ) {
            alert('Please fill the password');
            return false;
        }

        if ( !password_confirmation ) {
            alert('Please fill the password');
            return false;
        }

        if ( password != password_confirmation ) {
            alert("Password doesn't match");
            return false;
        }


        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/register',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                  name: name,
                  password: password,
                  password_confirmation: password_confirmation,
                  email: email
                }
            });
    
              if ( res.data.code === '200') {
                alert(res.data.message);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>TAMBAH USER BARU</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <label className='mb-1'>Name</label>
                        <input required className="form-control" type="text" placeholder="Full Name" 
                            value={name}
                            onChange={ (e) => setname(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Email</label>
                        <input required className="form-control" type="email" placeholder="Email Address" 
                            value={email}
                            onChange={ (e) => setemail(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Password</label>
                        <input required className="form-control" type="password" placeholder="Password" 
                            value={password}
                            onChange={ (e) => setpassword(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Password Confirmation</label>
                        <input required className="form-control" type="password" placeholder="Password Confirmation" 
                            value={password_confirmation}
                            onChange={ (e) => setpassword_confirmation(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-light mb-3 me-3' onClick={ () => nav(-1) }>BACK</button>
                        <button className='btn app-btn-primary mb-3' onClick={ handleSave }>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
