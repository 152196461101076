import axios from 'axios';
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { appApi } from '../Constant';

const AppCarousel = () => {
    const [data_slider, set_data_slider] = useState([]);

    const getAllSlider = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getAllSlider',
                headers: {
    
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                set_data_slider(res.data.data.data_slider);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
      }
    useEffect ( () => {
        getAllSlider()
    }, [])


    return (
    <>
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                {
                    data_slider.map( (v,i) => {
                        return (
                            <>
                                <div className={`carousel-item ${(i==0?'active':'')}`}>
                                    <img src={v.image} className="d-block w-100" 
                                        style={{  objectFit:'cover', objectPosition:'center'}} 
                                    alt="TYAGA DEV" />
                                </div>
                            </>
                        )
                    })
                }
                {/* <div className="carousel-item active">
                    <img src="https://images.unsplash.com/photo-1601122940398-04112e74fa35?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" className="d-block w-100" 
                        style={{ height: 568, objectFit:'cover', objectPosition:'center'}} 
                    alt="TYAGA DEV" />
                </div> */}
                {/* <div className="carousel-item">
                    <img src="https://images.unsplash.com/photo-1606444717545-7f5d882031cb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" className="d-block w-100" 
                        style={{ height: 568, objectFit:'cover', objectPosition:'center'}} 
                    alt="TYAGA DEV" />
                </div>
                <div className="carousel-item">
                    <img src="https://images.unsplash.com/photo-1609329007778-42630e6b1580?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" className="d-block w-100" 
                        style={{ height: 568, objectFit:'cover', objectPosition:'center'}} 
                    alt="TYAGA DEV" /> 
                </div>*/}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    </>
  )
}

export default AppCarousel