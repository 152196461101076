import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppHeroHeader } from '../../components/AppHeroHeader';
import { AppLoader1 } from '../../components/AppLoader1';
import { BeritaCard } from '../../components/BeritaCard';
import { appApi } from '../../Constant';

const BeritaPost = () => {
    const params = useParams();

    const [spiner,setSpiner] = useState(true);
    const [dataBerita, setDataBerita] = useState([]);
    const [dataBeritaLain, setDataBeritaLain] = useState([]);

    const getBeritaBySlug = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getBeritaBySlug',
                headers: {
    
                },
                data: {
                    slug: params.slug
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setDataBerita(res.data.data.dataBerita);
                setDataBeritaLain(res.data.data.dataBeritaLain);
                setSpiner(false);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect(() => {
        getBeritaBySlug();
    
      return () => {
         
      }
    }, [ ])
    
    if ( spiner ) {
        return (
            <AppLoader1 />
        )
    }

    return (
        <>
            <AppHeroHeader text="Berita" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-9'>
                        <div className='row mb-3'>
                            <div className='col-md-12'>
                                <div className='row '>
                                    <img src={dataBerita[0].thumbnail} alt='TYAGA DEV'  style={{
                                        height: 400, objectFit: 'contain', objectPosition: 'center'
                                    }}/>
                                </div>
                                <h1 className='h2'>{dataBerita[0].title}</h1>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <table className='w-100'>
                                    <tr>
                                        <td>
                                            <b>Posted By: </b> <span>{dataBerita[0].name}</span>
                                        </td>
                                        <td>
                                            <b>Posted At: </b> <span>{dataBerita[0].sys_created_on}</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col-md-12'>
                                { HTMLReactParser(dataBerita[0].body)}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2 className='fs-5 app-text-primary'>Berita Lainnya</h2>
                            </div>

                            {
                                dataBeritaLain.map( (v,i) => { return (
                                    <div className='col-md-12 mb-3' key={i}>
                                        <BeritaCard thumbnail={v.thumbnail} title={v.title} body={v.body} slug={v.slug} />
                                    </div>
                                )
                                })
                            }
                            <hr></hr>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BeritaPost