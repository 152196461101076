import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react'
import { AppHeroHeader } from '../../components/AppHeroHeader'
import { AppLoader1 } from '../../components/AppLoader1';
import AppMap from '../../components/AppMap';
import { appApi } from '../../Constant';

export const FasilitasKantorIndex = () => {
    const [contentx, setContentx ] = useState('');
    const [loader, setLoader ] = useState(true);
    
    const getFasilitasKantor = async () => {
      try {
          const res = await axios({
              method: 'post',
              url: appApi+'api/getFasilitasKantor',
              headers: {
                  "Authorization": "Bearer "+localStorage.getItem("userToken"),
              },
              data: {
              }
          });

          console.log("get", res.data.data.data1);
            if ( res.data.code === '200') {
              setContentx(res.data.data.data1)
              setLoader(false);
            } else {
              alert(res.data.message);
            }
      } catch (error) {
          // alert('Terjadi kesalahan pada server ')
          console.log(error)
      }
    }

    useEffect( () => {
      getFasilitasKantor();
    }, [])

    if ( loader ) {
      return (
        <AppLoader1 />
      )
    }

  return (
    <>
        <AppHeroHeader text="FASILITAS KANTOR" />
        <div className='container'>
          {
            contentx.map( (v,i) => {
              return (
                <div className='row mb-5 justify-content-center'>
                    <div className='col-md-4'>
                        <img src={v.image} alt='TYAGA DEV' width='356' height='200'  style={{
                          width: 356, height: 200, objectFit: 'cover', objectPosition: 'center'
                        }}/>
                    </div>
                    <div className='col-md-4 text-left'>
                      <h5>{v.title}</h5>
                      <p>
                        { HTMLReactParser(v.description) }
                      </p>
                    </div>
                </div>
              )
            })
          }
        </div>

        <div className='container mt-5'>
          <div className='row justify-content-center'>
            <div className='col-md-5'>
              <AppMap />
            </div>
          </div>
        </div>
    </>
  )
}
