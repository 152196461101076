import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AppTextEditor2 } from '../../../../components/AppTextEditor2';
import { appApi } from '../../../../Constant';

export const BeritaEdit = () => {
    const nav = useNavigate();
    const { state } = useLocation();

    console.log('state',state);

    const [title, setTitle] = useState(state.title);
    const [type, setType] = useState(state.type);
    const [bodyy, setBodyy] = useState(state.body);
    const [category, set_category] = useState([]);
    const [DataSetupCategory, setDataSetupCategory] = useState([]);

    const getDataSetupCategory = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getDataSetupCategory',
                headers: {
    
                },
                data: {
                    type: type
                }
            });
    
                if ( res.data.code === '200') {
                    // alert(res.data.message);
                    setDataSetupCategory(res.data.data.data);
                } else {
                    alert(res.data.message);
                }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    const handleIsiBerita = (data) => {
        console.log('handle berita',data);
        setBodyy(data);
    }

    const handleUpdate = async () => {
        
        var bodyFormData = new FormData();
        bodyFormData.append('id', state.id);
        bodyFormData.append('title', title);
        bodyFormData.append('type', type);
        bodyFormData.append('bodyy', bodyy);
        // bodyFormData.append('thumbnail', file);
        bodyFormData.append('category', JSON.stringify(category));

        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/publikasi/berita/updateBerita',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: bodyFormData
            });
    
              if ( res.data.code === '200') {
                alert(res.data.message);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () =>{
        getDataSetupCategory()
    }, [])

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>Edit Berita</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <h3>{ state.title }</h3>
                </div>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <label className='mb-1'>Judul</label>
                        <input className="form-control" type="text" placeholder="Masukan Judul Berita" 
                            value={title}
                            onChange={ (e) => setTitle(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Kategori</label>
                        <Select
                            isMulti
                            options={DataSetupCategory} 
                            getOptionLabel={(option) => `${option['category_name']}`}
                            getOptionValue={(option) => `${option['id']}`}
                            onChange={ (option) => set_category(option)}
                            
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Isi Berita</label>
                        {/* <AppTextEditor handleIsiBerita={handleIsiBerita} value={bodyy}/> */}
                        <AppTextEditor2 onChangeEditor={handleIsiBerita} value={bodyy}/>
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-light mb-3 me-3' onClick={ () => nav(-1) }>BACK</button>
                        <button className='btn app-btn-primary mb-3' onClick={ handleUpdate }>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
