import React from 'react'
import logoBrand from '../assets/images/Logo-Kejaksaan-2.png'
import { FaHome, FaMailBulk, FaWhatsapp } from "react-icons/fa";

export const AppFooter = () => {
  return (
    <>
        <div className='py-5 shadow border-top mt-5 border-success rounded'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='mb-3'>
                            <img src={logoBrand} height={50}/>
                        </div>
                        <table className='text-start'>
                            <tr>
                                <td width={50}><FaHome size={25}/></td>
                                <td>Address: Jl. Bhayangkara No.Desa, Danau Usung, Kec. Murung, Kabupaten Murung Raya, Kalimantan Tengah 73911</td>
                            </tr>
                            <tr>
                                <td><a className='text-dark' href="https://api.whatsapp.com/send?phone=6288808943541&text=Halo%20Kejari%20Murung%20Raya%20mau%20bertanya%20bisa%20dibantu?" target="_blank"> <FaWhatsapp size={25}/> </a></td>
                                <td> <a className='text-dark' href="https://api.whatsapp.com/send?phone=6288808943541&text=Halo%20Kejari%20Murung%20Raya%20mau%20bertanya%20bisa%20dibantu?" target="_blank"> Whatsapp: 088808943541 </a></td>
                            </tr>
                            <tr>
                                <td><FaMailBulk size={25}/></td>
                                <td>Email : <a href="mailto:ppkn.purukcahu@kejaksaan.go.id">ppkn.purukcahu@kejaksaan.go.id</a> </td>
                            </tr>
                        </table>
                    </div>
                    <div className='col'>

                    </div>
                </div>
            </div>

        </div>
    </>
  )
}
