import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDataTable } from '../../../../components/AppDataTable';
import { appApi } from '../../../../Constant';

export const DataFasilitasIndex = () => {
    
    const nav = useNavigate();

    const [dataFasilitas, setDataFasilitas] = useState([]);

    const columnsDataFasilitas = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Date',
            selector: row => row.sys_created_on,
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Action
                </button>
                <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={ () => handleEdit(row) }>EDIT</a></li>
                <li><a className="dropdown-item" onClick={ () => handleDelete(row) }>DELETE</a></li>
                </ul>
            </div>,
        },
    ];

    const handleEdit = (data) => {
        console.log(data);
        nav('/admin/profil/data-fasilitas/edit', {
            state : data
        }) 
    }

    const handleDelete = async (data) => {
        console.log(data);
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/profil/data-fasilitas/delete',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                    id: data.id
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setDataFasilitas(res.data.data.dataFasilitas);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    const getAllFasilitas = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/profil/data-fasilitas/getAllFasilitas',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setDataFasilitas(res.data.data.dataFasilitas);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllFasilitas();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA FASILITAS</h1>
            
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <button className='btn app-btn-primary' onClick={ () => nav('/admin/profil/data-fasilitas/add')}>TAMBAH FASILITAS</button>
                    </div>

                    <AppDataTable title="Data Fasilitas" colData={columnsDataFasilitas} rowData={dataFasilitas} />
                </div>
            </div>
        </div>
    </>
  )
}
