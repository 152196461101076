import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
    const nav = useNavigate();
    
    useEffect( () => {
        window.localStorage.clear();
        window.location.href = '/';
    }, [])

  return (
    <div className='text-center'>Logout</div>
  )
}
