import React, { useState } from 'react'
import axios from 'axios'
import { appApi } from '../../Constant';
import { useNavigate } from 'react-router-dom';
import { AppLoader1 } from '../../components/AppLoader1';

export const LoginIndex = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading_screen, set_loading_screen] = useState(false);
    const nav = useNavigate();

    const handleLogin = async () => {
        try {

            set_loading_screen(true);
            const res = await axios({
                method: 'post',
                url: appApi+'api/login',
                data: {
                  email: email,
                  password: password
                }
              });
          set_loading_screen(false);
    
              if ( res.data.code === '200') {
                window.localStorage.setItem('userToken', res.data.data.token);
                window.localStorage.setItem('user_id', res.data.data.user.id);
                window.localStorage.setItem('user_name', res.data.data.user.name);
                window.localStorage.setItem('user_email', res.data.data.user.email);
                alert(res.data.message);
                window.location.href = '/dashboard';
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server [login]')
            console.log(error)
        }
    }

    if ( loading_screen ) {
        return (
            <AppLoader1 />
        )
    }

  return (
    <div className='container' style={{ height: '100vh'}}>
        <div className='row justify-content-center'>
            <div className='col-md-3'>
                <div className='card'>
                    <div className='card-body'>
                        <form>
                            <div className='form-group mb-3'>
                                <label>Email</label>
                                <input 
                                    type='email'
                                    className='form-control'
                                    placeholder='Email address'
                                    value={email}
                                    onChange={ (e) => {
                                        setEmail(e.currentTarget.value)
                                    }}
                                />
                            </div>

                            <div className='form-group mb-3'>
                                <label>Password</label>
                                <input 
                                    type='password'
                                    className='form-control'
                                    placeholder='Password'
                                    value={password}
                                    onChange={ (e) => {
                                        setPassword(e.currentTarget.value)
                                    }}
                                />
                            </div>

                            <div className='form-group mb-3'>
                                <button type="button" className='btn app-btn-primary w-100' onClick={handleLogin}>LOGIN</button>
                            </div>

                            <div className='form-group mb-3'>
                                <a>Forgot Password?</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
