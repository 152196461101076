import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDataTable } from '../../components/AppDataTable';
import { appApi } from '../../Constant';
import { removeObjectWihId } from '../../helper/GlobalFunction';

export const DataSlider = () => {
    
    const nav = useNavigate();
    const [mode, set_mode] = 'index';

    const [data_slider, set_data_slider] = useState([]);

    const columnsdata_slider = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Image',
            selector: row => <img src={row.image}  />,
        },
        {
            name: 'Created',
            selector: row => row.sys_created_on,
        },
        {
            name: 'Action',
            selector: row => <button type='button' className='btn btn-danger' onClick={ () => handleDelete(row)}> Hapus </button>,
        },
    ];

    const getAllSlider = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/setting/slider/getAllSlider',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                set_data_slider(res.data.data.data_slider);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    const handleDelete = (row) => {
        if ( window.confirm('Apa kamu yakin menghapus '+row.title+' ?') ) {
            doDelete(row);
        } 
    }

    const doDelete = async (row) => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/setting/slider/delete',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                    id: row.id
                }
            });
    
              if ( res.data.code === '200') {
                getAllSlider();

                alert("Berhasil menghapus slider");
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }

    }
    


    useEffect( () => {
        getAllSlider();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA SLIDER</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <button className='btn btn-primary' onClick={ () => nav("/admin/data-slider/add")}>Tambah</button>

                    
                    <AppDataTable title="Data Slider" colData={columnsdata_slider} rowData={data_slider} />
                </div>
            </div>
        </div>
    </>
  )
}
