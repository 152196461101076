import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDataTable } from '../../../../components/AppDataTable';
import { appApi } from '../../../../Constant';

export const BeritaIndex = () => {
    
    const nav = useNavigate();

    const [dataBerita, setDataBerita] = useState([]);

    const columnsDataBerita = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Date',
            selector: row => row.sys_created_on,
        },
        {
            name: 'Category',
            selector: row => row.category_name,
        },
        {
            name: 'Created By',
            selector: row => row.name,
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Action
                </button>
                <ul className="dropdown-menu">
                <li><a className="dropdown-item"  onClick={ () => handleEdit(row) }>EDIT</a></li>
                <li><a className="dropdown-item" onClick={ () => handleDelete(row) }>DELETE</a></li>
                </ul>
            </div>,
            // selector: row => <button className='btn app-btn-primary' onClick={ () => handleEdit(row) }>EDIT</button>,
        },
    ];

    const handleEdit = (data) => {
        console.log('data edit', data);
        nav('/admin/publikasi/berita/edit', {
            state : data
        }) 
    }

    const handleDelete = async (data) => {
        console.log(data);
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/publikasi/berita/deleteBerita',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                    id: data.id
                }
            });
    
              if ( res.data.code === '200') {
                alert(res.data.message);
                getAllBerita();
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    const getAllBerita = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/publikasi/berita/getAllBerita',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setDataBerita(res.data.data.dataBerita);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            if ( error.response.status == '401') {
                alert('Invalid Token | Silahkan login kembali')
                window.localStorage.clear();
                window.location.href = '/login';
            } else {
                alert('Terjadi kesalahan pada server ')
            }
        }
    }


    useEffect( () => {
        getAllBerita();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA BERITA</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <button className='btn app-btn-primary' onClick={ () => nav('/admin/publikasi/berita/add')}>TAMBAH BERITA</button>
                    </div>

                    <AppDataTable title="Data Berita" colData={columnsDataBerita} rowData={dataBerita} />
                </div>
            </div>
        </div>
    </>
  )
}
