import React from 'react'

import { Link,  Navigate,  useOutlet } from "react-router-dom";

export const Guest = (props) => {
    const userToken = window.localStorage.getItem('userToken');

    if ( userToken ) {
        return <Navigate to="/" />
    }

  return props.children;
}
