import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AppHeroHeader } from '../../../components/AppHeroHeader'
import { AppTextEditor2 } from '../../../components/AppTextEditor2'
import { appApi } from '../../../Constant';

export const DataProfilePimpinan = () => {
  const [bodyy, setBodyy] = useState('');
  const [file, setFile] = useState(null);
  
  const handleIsiBody = (data) => {
    setBodyy(data);
  }

  const handleUpdate = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/page-data/savePage',
            headers: {
              "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
              id: 1,
              body: bodyy,
              page: 'pidus'
            }
        });

          if ( res.data.code === '200') {
            alert(res.data.message);
          } else {
            alert(res.data.message);
          }
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
    }
  }

  const handleGet = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/page-data/getPage',
            headers: {
                "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
              id: 1,
              body: bodyy,
              page: 'pidus'
            }
        });

          if ( res.data.code === '200') {
            setBodyy(res.data.data.data1[0].body)
          } else {
            alert(res.data.message);
          }
    } catch (error) {
        // alert('Terjadi kesalahan pada server ')
        console.log(error)
    }
  }

  useEffect(() => {

    // handleGet()

  }, [])

    const handleSave = async () => {
        console.log('gambar', file);
        var bodyFormData = new FormData();
        bodyFormData.append('image', file);
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/profil/profil-pimpinan/save',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                    "Content-Type": "multipart/form-data",
                },
                data: bodyFormData
            });
    
              if ( res.data.code === '200') {
                alert(res.data.message);
                window.location.reload();
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

  return (
    <>
        <AppHeroHeader text="Profil Pimpinan" />
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3'>
                <div className='mb-3'>
                    <label className='mb-1'>File PDF</label>
                    <input className="form-control" type="file"  placeholder="Masukan Judul" 
                        onChange={ (e) => setFile(e.target.files[0]) }
                    />
                </div>
              </div>
              <div className='mb-3'>
                  <button className='btn app-btn-primary mb-3' onClick={ handleSave }>SAVE</button>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
