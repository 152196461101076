import React, { useMemo, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { HomeIndex } from './screens/home/HomeIndex';
import { BrowserRouter, Route, Redirect, Routes } from 'react-router-dom';
import { AppNavbar } from './components/AppNavbar';
import { AppFooter } from './components/AppFooter';
import { PembinaanIndex } from './screens/pembinaan/PembinaanIndex';
import { VisiMisiIndex } from './screens/profil/VisiMisiIndex';
import { AuthContext } from './components/Contexts';
import { DashboardIndex } from './screens/admin/dashboard/DashboardIndex';
import { NotFound } from './screens/errors/NotFound';
import { AuthMiddleware } from './middlewares/AuthMiddleware';
import { LoginIndex } from './screens/login/LoginIndex';
import AuthRoute from './middlewares/AuthRoute';
import { Logout } from './screens/login/Logout';
import { BeritaIndex } from './screens/admin/publikasi/berita/BeritaIndex';
import { BeritaAdd } from './screens/admin/publikasi/berita/BeritaAdd';
import { BeritaEdit } from './screens/admin/publikasi/berita/BeritaEdit';
import { TugasWewenangIndex } from './screens/profil/TugasWewenangIndex';
import { NilaiSejarahIndex } from './screens/profil/NilaiSejarahIndex';
import BeritaPost from './screens/publikasi/BeritaPost';
import { PublikasiIndex } from './screens/publikasi/PublikasiIndex';
import { UserIndex } from './screens/admin/users/UserIndex';
import { UserAdd } from './screens/admin/users/UserAdd';
import { VisiMisiIndex as VisiMisiAdmin } from './screens/admin/profil-page/VisiMisiIndex';
import { TugasWewenangEditor } from './screens/admin/profil-page/TugasWewenangEditor';
import { DataFasilitasIndex } from './screens/admin/profil-page/data-fasilitas/DataFasilitasIndex';
import { FasilitasKantorIndex } from './screens/profil/FasilitasKantorIndex';
import { DataFasilitasAdd } from './screens/admin/profil-page/data-fasilitas/DataFasilitasAdd';
import { WBSIndex } from './screens/reformasi-birokrasi/WBSIndex';
import { DataWBSIndex } from './screens/admin/reformasi-birokrasi/DataWBSIndex';
import { StrukturOrganisasiIndex } from './screens/AdhyaksaDarmakarini/StrukturOrganisasiIndex';
import { DataStrukturOrganisasi } from './screens/admin/AdhyaksaDarmakarini/DataStrukturOrganisasiIndex';
import { DataStrukturOrganisasiAdd } from './screens/admin/AdhyaksaDarmakarini/DataStrukturOrganisasiAdd';
import { DataStrukturOrganisasiEdit } from './screens/admin/AdhyaksaDarmakarini/DataStrukturOrganisasiEdit';
import { DataWidget } from './screens/admin/widget/WidgetIndex';
import { WidgetAdd } from './screens/admin/widget/WidgetAdd';
import { WidgetEdit } from './screens/admin/widget/WidgetEdit';
import { PidumIndex } from './screens/e-ptsp/PidumIndex';
import { PidsusIndex } from './screens/e-ptsp/PidsusIndex';
import { DatunIndex } from './screens/e-ptsp/DatunIndex';
import { IntelejenIndex } from './screens/e-ptsp/IntelejenIndex';
import { IntelejenAdmin } from './screens/admin/page-data/IntelejenAdmin';
import { PidsusAdmin, PidusAdmin } from './screens/admin/page-data/PidsusAdmin';
import { PidumAdmin } from './screens/admin/page-data/PidumAdmin';
import { DatunAdmin } from './screens/admin/page-data/DatunAdmin';
import { Guest } from './middlewares/Guest';
import { WartaBengkayangIndex } from './screens/publikasi/WartaBengkayangIndex';
import { WartaAdhyaksaIndex } from './screens/publikasi/WartaAdhyaksaIndex';
import { Kegiatan } from './screens/AdhyaksaDarmakarini/Kegiatan';
import { JadwalSidang } from './screens/pelayanan/JadwalSidang';
import { DataFasilitasEdit } from './screens/admin/profil-page/data-fasilitas/DataFasilitasEdit';
import { DataProfilePimpinan } from './screens/admin/profil-page/DataProfilePimpinan';
import { ProfilPimpinanIndex } from './screens/profil/ProfilPimpinanIndex';
import DataWBSDetail from './screens/admin/reformasi-birokrasi/DataWBSDetail';
import { DataSlider } from './screens/admin/DataSlider';
import { DataSliderAdd } from './screens/admin/DataSliderAdd';
import PetaPemilu from './screens/pelayanan/PetaPemilu';


function App() {
  const [isAuth, setIsAuth] = useState(false);

  return (
    <>
        <BrowserRouter>
          <AppNavbar />
          <Routes>
            <Route path='*' element={ <NotFound /> } />  
            <Route path='/logout' element={ <Logout /> } />  
            <Route path='/' element={ <HomeIndex /> } />  
            <Route path='/login' element={ 
              <Guest>
                <LoginIndex /> 
              </Guest>
            } />  
            <Route path='/profil/visi-misi' element={ <VisiMisiIndex /> } />  
            <Route path='/profil/tugas-wewenang' element={ <TugasWewenangIndex /> } />  
            <Route path='/profil/nilai-sejarah' element={ <NilaiSejarahIndex /> } />  
            <Route path='/profil/fasilitas-kantor' element={ <FasilitasKantorIndex /> } />  
            <Route path='/profil/profil-pimpinan' element={ <ProfilPimpinanIndex /> } />  
            <Route path='/eptsp/pembinaan' element={ <PembinaanIndex /> } />
            <Route path='/struktur-organisasi' element={ <StrukturOrganisasiIndex /> } />
            <Route path='/kegiatan' element={ <Kegiatan /> } />
            <Route path='/intelejen' element={ <IntelejenIndex /> } />
            <Route path='/pidum' element={ <PidumIndex /> } />
            <Route path='/pidsus' element={ <PidsusIndex /> } />
            <Route path='/datun' element={ <DatunIndex /> } />
            <Route path='/barang-bukti-dan-rampasan' element={ <StrukturOrganisasiIndex /> } />

            {/* PELAYANAN  */}
              <Route path='/jadwal-sidang' element={ <JadwalSidang /> } />  
              <Route path='/peta-pemilu' element={ <PetaPemilu /> } />  
            {/* PELAYANAN  */}

            {/* PUBLIKASI  */}
              <Route path='/publikasi' element={ <PublikasiIndex /> } />  
              <Route path='/publikasi/berita/:slug' element={ <BeritaPost /> } />  
              <Route path='/publikasi/warta-bengkayang' element={ <WartaBengkayangIndex /> } />  
              <Route path='/publikasi/warta-adhyaksa' element={ <WartaAdhyaksaIndex /> } />  
            {/* PUBLIKASI  */}

            <Route path='/reformasi-birokrasi/wbs' element={ <WBSIndex /> } />

            {/* START AUTH SECTION */}
              <Route path='/dashboard' element={
                <AuthMiddleware >
                  <DashboardIndex /> 
                </AuthMiddleware> 
              } />

              <Route path='/admin/users' element={
                <AuthMiddleware >
                  <UserIndex /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/data-user/users/add' element={
                <AuthMiddleware >
                  <UserAdd /> 
                </AuthMiddleware> 
              } />

              <Route path='/admin/profil/visi-misi' element={
                <AuthMiddleware >
                  <VisiMisiAdmin />
                </AuthMiddleware> 
              } />
              <Route path='/admin/profil/tugas-wewenang' element={
                <AuthMiddleware >
                  <TugasWewenangEditor /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/profil/nilai-sejarah' element={
                <AuthMiddleware >
                  <BeritaEdit /> 
                </AuthMiddleware> 
              } />

              <Route path='/admin/profil/data-fasilitas' element={
                <AuthMiddleware >
                  <DataFasilitasIndex /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/profil/data-fasilitas/add' element={
                <AuthMiddleware >
                  <DataFasilitasAdd /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/profil/data-fasilitas/edit' element={
                <AuthMiddleware >
                  <DataFasilitasEdit /> 
                </AuthMiddleware> 
              } />

              <Route path='/admin/profil/profil-pimpinan' element={
                <AuthMiddleware >
                  <DataProfilePimpinan /> 
                </AuthMiddleware> 
              } />


              <Route path='/admin/publikasi/berita' element={
                <AuthMiddleware >
                  <BeritaIndex /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/publikasi/berita/add' element={
                <AuthMiddleware >
                  <BeritaAdd /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/publikasi/berita/edit' element={
                <AuthMiddleware >
                  <BeritaEdit /> 
                </AuthMiddleware> 
              } />

              <Route path='/admin/reformasi-birokrasi/data-wbs' element={
                <AuthMiddleware >
                  <DataWBSIndex /> 
                </AuthMiddleware> 
              } />
              <Route path='/admin/reformasi-birokrasi/data-wbs-detail' element={
                <AuthMiddleware >
                  <DataWBSDetail /> 
                </AuthMiddleware> 
              } />


              <Route path='/admin/adhyaksa-darmakarini/data-struktur-organisasi' element={
                <AuthMiddleware >
                  <DataStrukturOrganisasi />
                </AuthMiddleware> 
              } />
              <Route path='/admin/adhyaksa-darmakarini/data-struktur-organisasi/add' element={
                <AuthMiddleware >
                  <DataStrukturOrganisasiAdd />
                </AuthMiddleware> 
              } />
              <Route path='/admin/adhyaksa-darmakarini/data-struktur-organisasi/edit' element={
                <AuthMiddleware >
                  <DataStrukturOrganisasiEdit />
                </AuthMiddleware> 
              } />


              <Route path='/admin/widget' element={
                <AuthMiddleware >
                  <DataWidget />
                </AuthMiddleware> 
              } />
              <Route path='/admin/widget/add' element={
                <AuthMiddleware >
                  <WidgetAdd />
                </AuthMiddleware> 
              } />
              <Route path='/admin/widget/edit' element={
                <AuthMiddleware >
                  <WidgetEdit />
                </AuthMiddleware> 
              } />


              <Route path='/admin/intelejen' element={
                <AuthMiddleware >
                  <IntelejenAdmin />
                </AuthMiddleware> 
              } />
              <Route path='/admin/pidsus' element={
                <AuthMiddleware >
                  <PidsusAdmin />
                </AuthMiddleware> 
              } />
              <Route path='/admin/pidum' element={
                <AuthMiddleware >
                  <PidumAdmin />
                </AuthMiddleware> 
              } />
              <Route path='/admin/datun' element={
                <AuthMiddleware >
                  <DatunAdmin />
                </AuthMiddleware> 
              } />

            {/* END AUTH SECTION */}

              <Route path='/admin/data-slider' element={
                <AuthMiddleware >
                  <DataSlider />
                </AuthMiddleware> 
              } />
              <Route path='/admin/data-slider/add' element={
                <AuthMiddleware >
                  <DataSliderAdd />
                </AuthMiddleware> 
              } />

          </Routes>  
          <AppFooter />
        </BrowserRouter>
    </>
  );
}

export default App;
