import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AppHeroHeader } from '../../components/AppHeroHeader'
import { AppLoader1 } from '../../components/AppLoader1';
import { AppWidget } from '../../components/AppWidget';
import { BeritaCard } from '../../components/BeritaCard';
import { appApi } from '../../Constant';
import { getDataSetupCategory } from '../../service/api/GlobalApi';

export const PublikasiIndex = () => {
    const [loader, setLoader ] = useState(true);

    const [data_category, set_data_category] = useState([]);
    const [dataBerita, setDataBerita] = useState([]);
    const [type, setType] = useState('');

    const handleFilter = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getBeritaByType',
                headers: {
    
                },
                data: {
                    type: type
                }
            });
    
                if ( res.data.code === '200') {
                    // alert(res.data.message);
                    setDataBerita(res.data.data.dataBerita);
                    setLoader(false);
                } else {
                    alert(res.data.message);
                }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    useEffect( () => {
        handleFilter();
        handleCategory();
    }, [])

    const handleCategory = async () => {
        try {
            const res = await getDataSetupCategory();
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                set_data_category(res.data.data.data);
                console.log('array data',res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }
    
    if ( loader ) {
        return (
            <AppLoader1 />
        )
    }
  return (
    <>
        <AppHeroHeader text='PUBLIKASI' />
        
        <div className='container py-3'>
            <div className='row'>
                <div className='col-md-9'>
                    <div className='row mb-5'>
                        <label className='me-3 col-md-1'>Tipe</label>
                        <div className='col-4'>
                            <select className=' me-3 form-control' value={type}  onChange={ (e) => setType(e.currentTarget.value) }>
                                <option value=''>ALL</option>
                                {
                                    data_category.map( (v,i) => {
                                        return (
                                            <option value={v.category_name}>{v.category_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <button className='col-2 btn btn-sm app-btn-primary' onClick={ handleFilter }> FILTER </button>
                    </div>
                    <div className='row '>
                        {
                            dataBerita.map( (v,i) => { return (
                                <div className='col-md-4 mb-3'>
                                    <BeritaCard thumbnail={v.thumbnail} title={v.title} body={v.body} slug={v.slug} />
                                </div>
                            )
                            })
                        }
                    </div>
                </div>

                <div className='col-md-3'>
                        <AppWidget />
                </div>
            </div>
            
        </div>
    </>
  )
}
