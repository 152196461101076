import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const AppTextEditor2 = (props) => {
    const editorRef = useRef(props.value);
    // const val = useState()
    const log = () => {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
        // props.handleIsiBerita(editorRef.current.getContent())
        props.onChangeEditor(editorRef.current.getContent())
      }
    };
    return (
      <>
        <Editor
            onChange={ log }
            onFocus={log}
            onBlur={log}
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={props.value?props.value:"<p>Hello from Ameria!</p>"}
          init={{
            height: 500,
            menubar: false,
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount  print preview',
      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | print preview| removeformat',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </>
    );
}
