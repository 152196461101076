import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { tanggal_saja } from '../../../helper/GlobalFunction';

const DataWBSDetail = () => {
    const { state } = useLocation();
    useEffect( () => {
        console.log('items', state.item)
    }, [])
  return (
    <div>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>Detail Pelaporan</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='form-group'>
                        <small className='fw-bold'>Tanggal Lapor</small>
                        <p>{ tanggal_saja(state.item.sys_created_on)}</p>
                    </div>
                    <div className='form-group'>
                        <label className='fw-bold'>Nama Pelapor</label>
                        <p>{state.item.nama_pelapor}</p>
                    </div>
                    <div className='form-group mt-3'>
                        <label className='fw-bold'>Email Pelapor</label>
                        <p>{state.item.email_pelapor}</p>
                    </div>
                    <div className='form-group mt-3'>
                        <label className='fw-bold'>Kritik & Saran Pelaporr</label>
                        <p>{state.item.kritik_saran}</p>
                    </div>
                    <div className='form-group mt-3'>
                        <label className='fw-bold'>Hal. Pelaporan</label>
                        <p>{state.item.pelaporan}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DataWBSDetail