import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AppHeroHeader } from '../../../components/AppHeroHeader'
import { AppTextEditor2 } from '../../../components/AppTextEditor2'
import { appApi } from '../../../Constant';

export const DatunAdmin = () => {
  const [bodyy, setBodyy] = useState('');
  
  const handleIsiBody = (data) => {
    setBodyy(data);
  }

  const handleUpdate = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/page-data/savePage',
            headers: {
              "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
              body: bodyy,
              page: 'datun'
            }
        });

          if ( res.data.code === '200') {
            alert(res.data.message);
          } else {
            alert(res.data.message);
          }
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
    }
  }

  const handleGet = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/page-data/getPage',
            headers: {
                "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
              body: bodyy,
              page: 'datun'
            }
        });

          if ( res.data.code === '200') {
            setBodyy(res.data.data.data1[0].body)
          } else {
            alert(res.data.message);
          }
    } catch (error) {
        // alert('Terjadi kesalahan pada server ')
        console.log(error)
    }
  }

  useEffect(() => {

    handleGet()

  }, [])

  return (
    <>
        <AppHeroHeader text="Datun" />
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3'>
                <AppTextEditor2 onChangeEditor={handleIsiBody} value={bodyy} />
              </div>
              <div className='mb-3'>
                  <button className='btn app-btn-primary mb-3' onClick={ handleUpdate }>SAVE</button>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
