import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaBook, FaBuilding, FaMotorcycle, FaUser, FaUserLock, FaUserSecret, FaUserTie } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { primaryColor, goldColor, appApi } from '../../Constant'
import { BeritaCard } from '../../components/BeritaCard'
import AppCarousel from '../../components/AppCarousel'
import { AppWidget } from '../../components/AppWidget'

export const HomeIndex = () => {
  const nav = useNavigate();
  const [dataBerita, setDataBerita] = useState([]);
  const [data_slider, set_data_slider] = useState([]);

  const elip = (string,longChar) => {
    string  = (string.length > longChar ? string.substring(0,longChar)+"..." : string  )
    return string
  }

  const getBerita = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/getBerita',
            headers: {

            },
            data: {
            }
        });

          if ( res.data.code === '200') {
            // alert(res.data.message);
            setDataBerita(res.data.data.dataBerita);
          } else {
            alert(res.data.message);
          }
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
    }
  }

  useEffect(() => {
    getBerita();  

    setTimeout(function() {
      var elements = document.querySelectorAll('.css-lgbo0i');
      console.log('eleeem', elements)
      elements.forEach(function(element) {
        element.setAttribute('hidden', true);
      });

    }, 6000);
  
    return () => {
      
    }
  }, [])
  
  return (
    <>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12' >

                <AppCarousel />

              </div>
            </div>
          </div>

        <div id="services" className='container-fluid' style={{ color: primaryColor }}>
          <div>
            <div className='row'>
              <div className='col-md-9'>
                <h2 className='mb-5 text-center mt-5'>LAYANAN HUKUM KAMI</h2>
                <div className='row justify-content-evenly mb-3 text-start'>
                  <div className='col-md-4 card shadow mb-3'>
                    <div className='p-3'>
                      <Link className='app-text-primary' to='/eptsp/pembinaan'> 
                        <FaUserTie size={25} />
                        <span className='ms-3 fw-bold fs-5'>PEMBINAAN</span>
                      </Link>
                    </div>
                  </div>
                  <div className='col-md-4 card shadow mb-3'>
                    <div className='p-3'>
                      <Link className='app-text-primary' to='pidsus'> 
                        <FaBuilding size={25} />
                        <span className='ms-3 fw-bold fs-5'>PIDSUS</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='row justify-content-evenly mb-3 text-start'>
                  <div className='col-md-4 card shadow mb-3'>
                    <div className='p-3'>
                      <Link className='app-text-primary' to='intelejen'> 
                        <FaUserSecret size={25} />
                        <span className='ms-3 fw-bold fs-5'>INTELEJEN</span>
                      </Link>
                    </div>
                  </div>
                  <div className='col-md-4 card shadow mb-3'>
                    <div className='p-3'>
                      <Link className='app-text-primary' to='datun'> 
                        <FaBook size={25} />
                        <span className='ms-3 fw-bold fs-5'>DATUN</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='row justify-content-evenly mb-3 text-start'>
                  <div className='col-md-4 card shadow mb-3'>
                    <div className='p-3'>
                      <Link className='app-text-primary' to='pidum'> 
                        <FaUserLock size={30} />
                        <span className='ms-3 fw-bold fs-5'>PIDUM</span>
                      </Link>
                    </div>
                  </div>
                  <div className='col-md-4 card shadow mb-3'>
                    <div className='p-3'>
                      <FaMotorcycle size={30} />
                      <span className='ms-3 fw-bold fs-5'>BB && BR</span>
                    </div>
                  </div>
                </div>

                <div className='row mb-5 text-center'>
                  <div className='col'>
                    <b className='h1 text-white' >Daftar Berita</b>
                  </div>
                </div>
                <div className='row justify-content-center mb-3'>
                  {
                    dataBerita.map( (v,i) => { return (
                      <div className='col-md-4 mb-3'>
                        <BeritaCard thumbnail={v.thumbnail} title={v.title} body={v.body} slug={v.slug} />
                      </div>
                    )
                    })
                  }
                </div>
                <div className='row justify-content-center'>
                  <div className='col-md-4 text-center'>
                    <button className='btn app-btn-primary w-100' onClick={ () => {
                      nav('/publikasi');
                    }}>MORE</button>
                  </div>
                </div>

                <div data-mc-src="eddcef20-22fe-4798-98e9-db6d4f79a09b#instagram" className='mt-5'></div>
              </div>
              <div className='col-md-3'>
                <AppWidget />
              </div>
            </div>
          </div>
        </div>

        {/* <section className='container-fluid mb-3 parallax-home app-text-primary py-5 ' id="berita">
          <div className='row mb-5 text-center'>
            <div className='col'>
              <b className='h1 text-white' >Daftar Berita</b>
            </div>
          </div>
          <div className='row justify-content-center mb-3'>
            {
              dataBerita.map( (v,i) => { return (
                <div className='col-md-2 mb-3'>
                  <BeritaCard thumbnail={v.thumbnail} title={v.title} body={v.body} slug={v.slug} />
                </div>
              )
              })
            }
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-4 text-center'>
              <button className='btn app-btn-primary w-100' onClick={ () => {
                nav('/publikasi');
              }}>MORE</button>
            </div>
          </div>
        </section> */}
    </>
  )
}
