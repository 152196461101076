import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AppTextEditor2 } from '../../../../components/AppTextEditor2';
import { appApi } from '../../../../Constant';

export const DataFasilitasEdit = () => {
    const nav = useNavigate();
    const { state } = useLocation();


    const [title, setTitle] = useState(state.title);
    const [description, setdescription] = useState(state.description);
    const [file, setFile] = useState(null);

    useEffect( () => {
        console.log('state', state);
    }, [])

    const onChangeEditor = (data) => {
        console.log("handle isi berita",data);
        setdescription(data);
    }

    const handleSave = async () => {
        console.log('gambar', file);
        var bodyFormData = new FormData();
        bodyFormData.append('id', state.id);
        bodyFormData.append('title', title);
        bodyFormData.append('description', description);
        bodyFormData.append('image', file);
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/profil/data-fasilitas/save',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                    "Content-Type": "multipart/form-data",
                },
                data: bodyFormData
            });
    
              if ( res.data.code === '200') {
                alert(res.data.message);
                nav(-1);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>TAMBAH FASILITAS BARU</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <label className='mb-1'>Judul</label>
                        <input className="form-control" description="text" placeholder="Masukan Judul" 
                            value={title}
                            onChange={ (e) => setTitle(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Description</label>
                        <AppTextEditor2 value={description} onChangeEditor={onChangeEditor} />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Image</label>
                        <input className="form-control" type="file"  placeholder="Masukan Judul" 
                            onChange={ (e) => setFile(e.target.files[0]) }
                        />
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-light mb-3 me-3' onClick={ () => nav(-1) }>CANCEL</button>
                        <button className='btn app-btn-primary mb-3' onClick={ handleSave }>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
