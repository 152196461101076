import React from 'react'
import { Link } from 'react-router-dom'
import { AppName } from '../Constant'

export const AppModalMenu = () => {
  return (
    <>
        <div className="modal fade" id="menuModal" tabIndex={-1} aria-labelledby="menuModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="menuModalLabel">{AppName}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Pages
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
        
        {/* START CANVAS */}
        <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">{ AppName}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Data User
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/users">Users</Link></li>
                    </ul>
                </div>
                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Profil
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/profil/visi-misi">Visi & Misi</Link></li>
                        <li><Link  className="dropdown-item" to="/admin/profil/tugas-wewenang">Tugas & Wewenang</Link></li>
                        <li><Link  className="dropdown-item" to="/admin/profil/data-fasilitas">Fasilitas</Link></li>
                        <li><Link  className="dropdown-item" to="/admin/profil/profil-pimpinan">Profil Pimpinan</Link></li>
                    </ul>
                </div>
                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Publikasi
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/publikasi/berita">Berita</Link></li>
                    </ul>
                </div>

                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Reformasi Birokrasi
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/reformasi-birokrasi/data-wbs">Data WBS</Link></li>
                    </ul>
                </div>

                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Adhyaksa Darmakarini
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/adhyaksa-darmakarini/data-struktur-organisasi">Data Struktur Organisasi</Link></li>
                    </ul>
                </div>

                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Widget
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/widget">Data Widget</Link></li>
                    </ul>
                </div>

                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Pages
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/intelejen">Intelejen</Link></li>
                        <li><Link  className="dropdown-item" to="/admin/pidsus">Pidsus</Link></li>
                        <li><Link  className="dropdown-item" to="/admin/pidum">Pidum</Link></li>
                        <li><Link  className="dropdown-item" to="/admin/datun">Datun</Link></li>
                    </ul>
                </div>
                <div className="dropdown mt-3">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown">
                        Setting
                    </button>
                    <ul className="dropdown-menu w-100">
                        <li><Link  className="dropdown-item" to="/admin/data-slider">Slider</Link></li>
                    </ul>
                </div>
            </div>
        </div>

        {/* END CANVAS */}
    </>
  )
}
