import axios from 'axios'
import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../Constant'

export const PidsusIndex = () => {
    const [contentx, setContentx ] = useState('');

    const getPage = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/getPage',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                    page: 'pidsus'
                }
            });
  
            if ( res.data.data.data1.length > 0 ) {
                if ( res.data.code === '200') {
                  setContentx(res.data.data.data1[0].body)
                } else {
                  alert(res.data.message);
                }
            }
        } catch (error) {
            // alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }
  
        useEffect( () => {
          getPage();
        }, [])
  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>Pidsus</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    {  HTMLReactParser(contentx) }
                </div>
            </div>
        </div>
    </>
  )
}
