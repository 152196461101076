import axios from "axios";
import { appApi } from "../../Constant";

export const getWidget = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/getWidget',
            headers: {
                // "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
            }
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}


export const getAllDataWidget = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/widget/getAllDataWidget',
            headers: {
                "Authorization": "Bearer "+localStorage.getItem("userToken"),
            },
            data: {
            }
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}



export const saveWidget = async (bodyFormData) => {
    try {
        const res = await axios({
            method: 'post',
            url: appApi+'api/widget/saveWidget',
            headers: {
                "Authorization": "Bearer "+localStorage.getItem("userToken"),
                "Content-Type": "multipart/form-data",
            },
            data: bodyFormData
        });

          return res;
    } catch (error) {
        alert('Terjadi kesalahan pada server ')
        console.log(error)
        return error;
    }
}