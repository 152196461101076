import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppTextEditor2 } from '../../../components/AppTextEditor2';
import { appApi } from '../../../Constant';
import { getPosition, saveDataStrukturOrganisasi } from '../../../service/api/StrukturOrganisasiApi';

export const DataStrukturOrganisasiAdd = () => {
    const nav = useNavigate();
    const [Data, setData] = useState([]);


    const [_nama, set_nama] = useState('');
    const [_facebook, set_facebook] = useState('');
    const [_instagram, set_instagram] = useState('');
    const [_position_id, set_position_id] = useState('');
    const [file, setFile] = useState(null);

    const onChangeEditor = (data) => {
        console.log("handle isi berita",data);
        set_facebook(data);
    }

    const handleSave = async () => {
        console.log('gambar', file);
        var bodyFormData = new FormData();
        bodyFormData.append('nama', _nama);
        bodyFormData.append('facebook', _facebook);
        bodyFormData.append('instagram', _instagram);
        bodyFormData.append('position_id', _position_id);
        bodyFormData.append('image', file);
        try {
            const res = await saveDataStrukturOrganisasi(bodyFormData)
    
              if ( res.data.code === '200') {
                alert(res.data.message);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    const getAllData = async () => {
        try {
            const res = await getPosition()
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setData(res.data.data.data);
                console.log(res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllData();
    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>TAMBAH DATA BARU</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <label className='mb-1'>Nama</label>
                        <input className="form-control" type="text" placeholder="Masukan Nama" 
                            value={_nama}
                            onChange={ (e) => set_nama(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Alamat Facebook</label>
                        <input className="form-control" type="text" placeholder="Masukan Facebook" 
                            value={_facebook}
                            onChange={ (e) => set_facebook(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Alamat Instagram</label>
                        <input className="form-control" type="text" placeholder="Masukan Instagram" 
                            value={_instagram}
                            onChange={ (e) => set_instagram(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Jabatan { _position_id }</label>
                        <select className="form-control"
                            onChange={ (e) => set_position_id(e.currentTarget.value)}
                        >
                            <option>-- PILIH --</option>
                            {
                                Data.map( (v,i) => {
                                    return (
                                        <option value={v.position_id}>{v.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'>Foto</label>
                        <input className="form-control" type="file"  placeholder="Masukan Judul" 
                            onChange={ (e) => setFile(e.target.files[0]) }
                        />
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-light mb-3 me-3' onClick={ () => nav(-1) }>CANCEL</button>
                        <button className='btn app-btn-primary mb-3' onClick={ handleSave }>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
