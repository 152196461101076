import React from 'react'
import { FaBullhorn, FaIdCard, FaIdCardAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const PembinaanIndex = () => {
  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>PEMBINAAN</h1>
        </div>
        <div className='container' >
            <div className='text-start'>
                {/* <div className='row align-items-center mb-3'>
                    <div className='col'>
                        <Link className='onHover'><FaBullhorn size={50} className="me-3"/> 
                            <span className='h4 ms-1'>PENGUMUMAN LELANG</span> 
                        </Link>
                    </div>
                </div>
                <div className='row align-items-center mb-3 '>
                    <div className='col'>
                        <Link className='onHover'><FaIdCard size={50} className="me-3" /> 
                            <span className='h4 ms-1'>INFORMASI REKRUTMEN PEGAWAI</span> 
                        </Link>
                    </div>
                </div>
                <div className='row align-items-center mb-3 '>
                    <div className='col'>
                        <Link className='onHover'><FaIdCardAlt size={50} className="me-3" /> 
                            <span className='h4 ms-1'>INTERSHIP PROGRAM</span> 
                        </Link>
                    </div>
                </div> */}
            </div>
        </div>
    </>
  )
}
