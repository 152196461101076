import React from 'react'

export const AppLoader1 = () => {
  return (
    <>
    <div style={{ height: '100vh'}}>

    </div>
      <div className="loader"></div>
    </>
  )
}
