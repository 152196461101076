import React, { useEffect, useState } from 'react'
import HTMLReactParser from 'html-react-parser'
import { getWidget } from '../service/api/WidgetApi'

import tilang from '../assets/images/pelayanan-tilang-1.jpg'

export const AppWidget = () => {

    const [Data, setData] = useState([]);

    const getApiWidget = async () => {
        try {
            const res = await getWidget();
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setData(res.data.data.data);
                console.log('array data',res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    useEffect( () => {
        getApiWidget();
    }, [])
  return (
    <div>
        <div  className='mt-5'>
            <h3></h3>
            <a className='text-dark' href="https://tilang.kejaksaan.go.id/" target="_blank">
            <img src={ tilang } alt='TYAGA DEV'  style={{
                objectFit: 'cover', objectPosition: 'center'
            }}/>
            </a>
        </div>
        {
            Data.map( (v,i) => {
                return (
                    <div key={i} className='mt-5'>
                        <h3>{v.nama}</h3>
                        {
                            HTMLReactParser(v.body_widget)
                        }
                    </div>
                )
            })
        }
    </div>
  )
}
