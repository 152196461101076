import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDataTable } from '../../../components/AppDataTable';
import { appApi } from '../../../Constant';

export const UserIndex = () => {
    
    const nav = useNavigate();

    const [dataUser, setDataUser] = useState([]);

    const columnsDataUser = [
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Created',
            selector: row => row.created_at,
        },
        // {
        //     name: 'Action',
        //     selector: row => <button className='btn app-btn-primary' onClick={ () => handleEdit(row) }>EDIT</button>,
        // },
    ];

    const handleEdit = (data) => {
        console.log(data);
        nav('/admin/publikasi/berita/edit', {
            state : data
        }) 
    }


    const getAllUser = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: appApi+'api/data-user/users/getAllUser',
                headers: {
                    "Authorization": "Bearer "+localStorage.getItem("userToken"),
                },
                data: {
                }
            });
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setDataUser(res.data.data.dataUser);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllUser();

    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>DATA USER</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <button className='btn app-btn-primary' onClick={ () => nav('/admin/data-user/users/add')}>TAMBAH USER</button>
                    </div>

                    <AppDataTable title="Data User" colData={columnsDataUser} rowData={dataUser} />
                </div>
            </div>
        </div>
    </>
  )
}
