import React from 'react'

const PetaPemilu = () => {
  return (
    <div>
        <iframe src={`https://murung-raya-map.aldyweb.com/`} 
            width="100%" height="500" 
            style={{border: 'none'}}
            allowFullScreen>
        </iframe>
    </div>
  )
}

export default PetaPemilu