export const tanggal_saja = (date_x) => {
    const date = new Date(date_x);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const tanggal = date.toLocaleDateString('id-ID', options);
    return tanggal;
}


export const removeObjectWihId = (arr,obj_id) => {
    const updatedArr = arr.filter(item => item.id !== obj_id)
    return updatedArr;
}