import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AppTextEditor2 } from '../../../components/AppTextEditor2';
import { appApi } from '../../../Constant';
import { getPosition, saveDataStrukturOrganisasi } from '../../../service/api/StrukturOrganisasiApi';
import { saveWidget } from '../../../service/api/WidgetApi';

export const WidgetEdit = () => {
    const nav = useNavigate();
    const { state } = useLocation();
    const [Data, setData] = useState([]);


    const [_nama, set_nama] = useState(state.nama);
    const [_body_widget, set_body_widget] = useState(state.body_widget);
    const [_instagram, set_instagram] = useState(state.instagram);
    const [_id, set_id] = useState(state.id);
    const [file, setFile] = useState(null);


    const handleSave = async () => {
        console.log('gambar', file);
        var bodyFormData = new FormData();
        bodyFormData.append('nama', _nama);
        bodyFormData.append('body_widget', _body_widget);
        bodyFormData.append('id', state.id);
        try {
            const res = await saveWidget(bodyFormData)
    
              if ( res.data.code === '200') {
                alert(res.data.message);
                nav(-1)
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }

    const getAllData = async () => {
        try {
            const res = await getPosition()
    
              if ( res.data.code === '200') {
                // alert(res.data.message);
                setData(res.data.data.data);
                console.log(res.data.data.data);
              } else {
                alert(res.data.message);
              }
        } catch (error) {
            alert('Terjadi kesalahan pada server ')
            console.log(error)
        }
    }


    useEffect( () => {
        getAllData();
    }, []);

  return (
    <>
        <div className='container-fluid app-header app-bg-primary text-light p-2 mb-5'>
            <h1 className='text-center h4'>EDIT { state.nama }</h1>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-3'>
                        <label className='mb-1'>Nama</label>
                        <input className="form-control" type="text" placeholder="Masukan Nama" 
                            value={_nama}
                            onChange={ (e) => set_nama(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='mb-1'> Widget </label>
                        <input className="form-control" type="text" placeholder="Masukan Embed" 
                            value={_body_widget}
                            onChange={ (e) => set_body_widget(e.currentTarget.value) }
                        />
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-light mb-3 me-3' onClick={ () => nav(-1) }>CANCEL</button>
                        <button className='btn app-btn-primary mb-3' onClick={ handleSave }>SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
