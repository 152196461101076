import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import InsertImage from '@ckeditor/ckeditor5-image/tests/image';
// import ImageInsertViaUrl from '@ckeditor/ckeditor5-image-upload/src/imageinsertviaurl';

function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    }
  );
}

export const AppTextEditor = (props) => {

    const handleOnChange = (data) => {
        props.handleIsiBerita(data)
    }

    return (
        
        <>
            <CKEditor
                editor={ ClassicEditor }
                data={props.value?props.value:"<p>Hello from Ameria!</p>"}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    handleOnChange({ event, editor, data });
                    console.log( { event, editor, data } );
                } }
                onBlur={ ( event, editor ) => {
                    const data = editor.getData();
                    console.log( 'Blur.', data );
                    handleOnChange({ event, editor, data });
                } }
                onFocus={ ( event, editor ) => {
                    const data = editor.getData();
                    console.log( 'Focus.', editor );
                    handleOnChange({ event, editor, data });
                } }
            />
        </>
    );
}